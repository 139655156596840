













































import Base from '@/mixins/Base.vue';
import { ISpObject, ISpObjectResponse } from '@/interfaces/spobject';

const component = Base.extend({
    data() {
        return {
            active: false,
            confirmation: '',
            object: undefined as any | ISpObject,
        };
    },
    computed: {
        validated() : boolean {
            return this.confirmation === this.object.name;
        },
    },
    mounted() {
        this.active = true;
        this.getobject();
    },
    methods: {
        getobject(): void {
            this.get<ISpObjectResponse>(`objects/${this.$route.params.id}`).then(({ data }) => {
                this.object = data.data;
            });
        },
        save(): void {
            if (this.validated) {
                this.delete(`objects/${this.object.id}`).then(() => {
                    this.$router.push({ name: 'objects.list' });
                    this.$emit('reload');
                });
            }
        },
    },
});

export default component;
